import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './UserDetail.css';

const ClownVideos = () => {
    // Family-friendly clown videos
    const videos = [
        {
            id: 'jj0CmnxuTaQ',
            title: 'Funny Circus Clown Performance'
        },
        {
            id: 'pHlSE9j5FGY',
            title: 'Classic Circus Clowns'
        }
    ];

    return (
        <div className="video-section">
            {videos.map((video) => (
                <div key={video.id}>
                    <h3 className="video-title">{video.title}</h3>
                    <div className="video-container">
                        <iframe
                            src={`https://www.youtube.com/embed/${video.id}?autoplay=0&controls=1`}
                            title={video.title}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

const Clown = () => (
    <svg className="clown" viewBox="0 0 100 100">
        {/* Face */}
        <circle cx="50" cy="50" r="40" fill="#fff" stroke="#000" strokeWidth="2"/>
        
        {/* Red Nose */}
        <circle className="clown-nose" cx="50" cy="55" r="8" fill="#ff4444"/>
        
        {/* Eyes */}
        <circle cx="35" cy="40" r="5" fill="#000"/>
        <circle cx="65" cy="40" r="5" fill="#000"/>
        
        {/* Smile */}
        <path d="M30 60 Q50 75 70 60" fill="none" stroke="#000" strokeWidth="3"/>
        
        {/* Hair */}
        <circle cx="25" cy="25" r="10" fill="#ff6b6b"/>
        <circle cx="75" cy="25" r="10" fill="#4ecdc4"/>
        <circle cx="50" cy="15" r="10" fill="#45b7d1"/>
    </svg>
);

const Balloons = () => {
    const balloonColors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4'];
    
    return (
        <div className="balloons">
            {balloonColors.map((color, index) => (
                <svg 
                    key={index}
                    className="balloon"
                    viewBox="0 0 50 60"
                    style={{ fill: color }}
                >
                    <path d="M25,1 
                        C38.8,1 50,12.2 50,26 
                        C50,39.8 38.8,51 25,51 
                        C11.2,51 0,39.8 0,26 
                        C0,12.2 11.2,1 25,1 
                        M25,51 
                        L30,60 
                        L20,60 
                        L25,51" />
                </svg>
            ))}
        </div>
    );
};

function UserDetail() {
    const { user, message } = useParams();
    const [showMessage, setShowMessage] = useState(false);

    // Add YouTube API script
    useEffect(() => {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }, []);

    return (
        <div className="user-detail">
            <Balloons />
            <Clown />
            <div className="user-detail-content">
                <h1>{decodeURIComponent(user)}</h1>
                <p className="message">{decodeURIComponent(message)}</p>
                <button 
                    className="click-me-button"
                    onClick={() => setShowMessage(true)}
                >
                    Click Me!
                </button>
                {showMessage && (
                    <p className="special-message">
                        I hope {decodeURIComponent(user)} has an awesome day!
                    </p>
                )}
                <ClownVideos />
                <Link to="/" className="back-link">Back to Users</Link>
            </div>
        </div>
    );
}

export default UserDetail;
