import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import UserDetail from './components/UserDetail';
import styles from './App.module.css';
import config from './config';

// Table Header Component
const TableHeader = () => (
  <div className={styles.tableHeader}>
    <div className={styles.headerCell}>User</div>
    <div className={styles.headerCell}>Message</div>
  </div>
);

// Table Row Component
const TableRow = ({ user }) => (
  <div className={styles.tableRow}>
    <div className={styles.tableCell}>
      <Link
        to={`/user/${encodeURIComponent(user.user)}/${encodeURIComponent(user.message)}`}
        className={styles.userLink}
      >
        {user.user}
      </Link>
    </div>
    <div className={styles.tableCell}>{user.message}</div>
  </div>
);

// User List Component
const UserList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isPolling, setIsPolling] = useState(false);

  // Fetch users from the backend
  const fetchUsers = async () => {
    try {
      setIsPolling(true);
      const apiUrl = `${config.apiUrl}/api/users`;
      console.log('Fetching users from:', apiUrl);

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch users: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Fetched users:', data);
      setUsers(data);
      setError(null);
      setLastUpdate(new Date().toLocaleTimeString());
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users. Please try again later.');
    } finally {
      setIsPolling(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchUsers();
  }, []);

  // Set up polling
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Polling for updates...');
      fetchUsers();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.App}>
      <h1>Users and Messages</h1>
      {error && <div className={styles.error}>{error}</div>}
      {lastUpdate && (
        <div className={styles.updateInfo}>
          Last updated: {lastUpdate}
          {isPolling && <span className={styles.polling}> (Updating...)</span>}
        </div>
      )}
      <div className={styles.usersTable}>
        <TableHeader />
        {users.map((user) => (
          <TableRow key={user.id} user={user} />
        ))}
      </div>
    </div>
  );
};

// Main App Component
const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<UserList />} />
      <Route path="/user/:user/:message" element={<UserDetail />} />
    </Routes>
  </Router>
);

export default App;
